<template>
    <div class="d-flex" id="error-page">
        <div class="error-content">
            <h1>404</h1>
            <h2>Oops! This page could not be found</h2>
            <p>Sorry but the page you are looking for does not exist, have been removed, name changed or is temporary unavailable.</p>
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style lang="scss">
    #error-page {
        min-height: 400px;
        .error-content {
            margin: auto;
            max-width: 380px;
            padding: 20px;
            h1 {
                font-size: 90px;
                font-weight: bold;
                color: #dd3333;
                -webkit-text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.26);
                text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.26);
            }

            h2 {
                margin-top: 10px;
                font-size: 30px;
                font-weight: bold;
            }

            p {
                margin-top: 20px;
                font-size: 18px;
                line-height: 22px;
            }
        }
    }
</style>